import React from 'react';
import { useEffect } from 'react';

// Image Import
import LockIcon from '/icons/lock-grey.svg';

// Mui Imports
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { sendOtp, verifyOtp } from '../../../redux/userActions';
import { useTranslation } from 'react-i18next';

export default function SignupVerify() {
  const params = useLocation();
  const [values, setValues] = React.useState();
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState('');
  const [match, setMatch] = React.useState(true);
  const [resent, setResent] = React.useState(false);
  const [timer, setTimer] = React.useState(60);
  const { t } = useTranslation();

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const resend = async () => {
    const sendOTPBody = {
      forgotPassword: false,
      resend: true,
    };
    if (values.email.length) {
      sendOTPBody['email'] = values?.email;
    } else if (values.phone.length) {
      sendOTPBody['phoneNumber'] = values?.phone;
    }
    if (timer === 0) {
      const res = await sendOtp(sendOTPBody);
      if (res.data.success) {
        setResent(true);
        setTimeout(() => {
          setResent(false);
        }, 2000);
      }
      setTimer(60);
    }
  };

  const authenticate = async () => {
    if (otp.length < 4) {
      setMatch(false);
    } else {
      const verifyOTPBody = {
        otp: otp,
      };
      if (values.email.length) {
        verifyOTPBody['email'] = values?.email;
      } else if (values.phone.length) {
        verifyOTPBody['phoneNumber'] = values?.phone;
      }
      const res = await verifyOtp(verifyOTPBody);
      if (!res.data.success) {
        setMatch(false);
      } else {
        localStorage.setItem('theToken', res.data.data.token);
        navigate('/signup/create-password', {
          state: { values: values, user: res.data.data },
        });
      }
    }
  };

  React.useEffect(() => {
    if (params.state?.values == undefined) navigate('/');
    else {
      setValues(params.state.values);
    }
  }, [navigate, params]);

  return (
    <div className='Authform__container'>
      <Link to={'/signup'}>
        <div className='Authform__back_button'>
          <i className='fa-solid fa-arrow-left'></i>
          <h1 className='auth_back_fs'>{t('back')}</h1>
        </div>
      </Link>
      <div className='Authform__wrapper single_column'>
        <form className='Authform__form_group'>
          <div className='Authform__headline_space'>
            <Link to={'/signup'}>
              <div className='Auth_Back_mobile_btn'>
                <i className='fa-solid fa-arrow-left'></i>
                <h1 className='auth_back_fs'>{t('back')}</h1>
              </div>
            </Link>
            <h1 className='auth_heading_fs'>{t('signupVerify_heading')}</h1>
            <p className='auth_subheading_fs'>{t('signupVerify_subheading')}</p>
          </div>
          <div className='Authform__form_body'>
            <div className='Input__table'>
              <div className='Input__row single_column'>
                <div className='Input__column'>
                  <p className='Input__label_fs'>
                    {' '}
                    {values?.email && t('email_address')}{' '}
                    {values?.email && values?.phone && '/'}{' '}
                    {values?.phone && t('phone_number')}
                  </p>
                  <div className='otp_email_verify'>
                    <h1>
                      {values?.email} {values?.email && values?.phone && '/'}{' '}
                      {values?.phone}
                    </h1>
                    {/* <i className="fa-solid fa-pencil"></i> */}
                  </div>
                </div>
              </div>

              <div
                className='Input__row single_column'
                style={{ position: 'relative' }}
              >
                <div className='Input__column'>
                  <p className='Input__label_fs'>{t('otp')} ( OTP )</p>
                  <MuiOtpInput length={4} value={otp} onChange={handleChange} />
                </div>
                <div
                  onClick={resend}
                  style={{
                    opacity: 0.6,
                    position: 'absolute',
                    right: '0',
                    bottom: '-2.2rem',
                    cursor: 'pointer',
                  }}
                >
                  <span style={{ color: 'blue' }} className=' Input__label_fs'>
                    {t('resend_otp')}{' '}
                    {timer > 0 &&
                      'in 00 : ' + (timer < 10 ? '0' + timer : timer)}{' '}
                  </span>
                </div>
                {!match && (
                  <span className='Input__error_fs'>
                    {t('otp_not_matched')}
                  </span>
                )}
              </div>

              <div className='Input__row single_column'>
                <div
                  className='Authform__secure_button'
                  style={{ cursor: 'default' }}
                >
                  <img src={LockIcon} alt='LockIcon' />
                  <p className='auth__secure_fs'> {t('security_msg')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='Authform__form_actions'>
            <div className='Authform__submit_button'>
              <button
                type='button'
                className='mi_btn mi_btn_medium mi_btn_primary'
                onClick={authenticate}
              >
                <span> {t('verify_button')}</span>
                <i className='fa-solid fa-arrow-right'></i>
              </button>
            </div>

            {/* <div className="Authform__redirection_links">
              <div className="redirection_links_button">
                <span className="redirection_links_main_fs">
                  Didn’t recieve OTP?
                  <div
                    onClick={resend}
                    className="redirection_links_fs cursorP"
                  >
                    {" "}
                    Resend Again !
                  </div>
                </span>
              </div>
              {resent && <span className="Input__success_fs">OTP resent!</span>}
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}
