import React, { useState } from "react";
import Header from "../../../shared/header/Header";
import Sidebar from "../../../shared/sidebar/Sidebar";
import "./Pagelayout.css";
import { Outlet } from "react-router-dom";
import MessagePopup from "../../../shared/popup/MessagePopup";
import { useSelector } from "react-redux";
import ChatAI from "../../../shared/chatbot/ChatAI";
import Footer from "../../../shared/footer/Footer";

export default function Pagelayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const popupData = useSelector((state) => state.popup.popupStatus);
  return (
    <React.Fragment>
      <div className='Pagelayout__main_wrapper'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div
          className={`Pagelayout__body_wrapper ${
            sidebarOpen && "sidebar_shrinkable"
          }`}
        >
          <div
            className={`Pagelayout__sidebar_container ${
              sidebarOpen && "sidebar_shrinkable active"
            }`}
          >
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          </div>
          <div className='Pagelayout__page_container bg__full_cover'>
            <div className='content_provider'>
              <Outlet />
            </div>

            <div
              onClick={() => setSidebarOpen(!sidebarOpen)}
              className={` ${sidebarOpen && "backdrop"}`}
            ></div>
          </div>
        </div>
        <Footer />
      </div>
      <ChatAI />

      <MessagePopup
        open={popupData.open}
        type={popupData.type}
        message={popupData.message}
        action={popupData.action}
        page={popupData.page}
        title={popupData.title}
        element={popupData.element}
      />
    </React.Fragment>
  );
}
