import "./MessagePopup.css";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

// Icons
import SuccessIcon from "/icons/popup/success.svg";
import ErrorIcon from "/icons/popup/fail.svg";
import WarringIcon from "/icons/popup/warning.svg";
import InfoIcon from "/icons/popup/question.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closePopup } from "../../store/slices/popupSlice";
import { useTranslation } from "react-i18next";

export default function MessagePopup({
  open,
  type,
  message,
  action,
  title,
  page,
  element,
}) {
  const Navigate = useNavigate();

  const dispatch = useDispatch();

  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className="MessagePopup__wrapper">
          <div className="MessagePopup__close">
            <div
              className="ModalPopup__close_icon"
              onClick={() => dispatch(closePopup())}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="MessagePopup__Icon">
            <img
              src={
                type === "success"
                  ? SuccessIcon
                  : type === "error"
                  ? ErrorIcon
                  : type === "warn"
                  ? WarringIcon
                  : type === "info" && InfoIcon
              }
              alt="Icon"
            />
          </div>
          <div className="MessagePopup__Message">
            <div className="MessagePopup__Message_content">
              <div className="error__heading">
                <h3 className="popup__message_title_fs">
                  {type === "success"
                    ? t("successful")
                    : type === "error"
                    ? t("oops_something_went_wrong")
                    : type === "warn"
                    ? t("please_try_again")
                    : type === "info" && t("know_about_this")}
                </h3>

                <h1 className="popup__message_subtitle_fs">{title}</h1>
              </div>

              {element && element}

              {message ? (
                <div className="Error__alerts_group">
                  {Array.isArray(message) ? (
                    <>
                      {message?.map((msg, i) => (
                        <div className="Error__alerts">
                          <p className="popup__message_body_fs error_tone">
                            {msg?.msg}
                          </p>
                        </div>
                      ))}
                    </>
                  ) : (
                    <p className="popup__message_body_fs error_tone">
                      {message}
                    </p>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          {action && (
            <div className="MessagePopup__Action">
              <button
                className="mi_btn mi_btn_medium mi_btn_primary"
                onClick={() => {
                  Navigate(action);
                  dispatch(closePopup());
                }}
              >
                <span>{t("done")}</span>
              </button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
