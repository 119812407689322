import React from "react";
import { Link, useNavigate } from "react-router-dom";

// Image Import
import Celebration from "/icons/celebration.svg";
import OnboardImage from "/illustrations/onboard.svg";
import { useTranslation } from "react-i18next";

export default function Onboard() {
  const Navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const {t} = useTranslation()

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <React.Fragment>
      <div className="Authform__container">
        <Link to={"/"}>
          <div className="Authform__back_button">
            <i className="fa-solid fa-arrow-left"></i>
            <h1 className="auth_back_fs">{t("back")}</h1>
          </div>
        </Link>
        <div className="Authform__wrapper single_column">
          <div className="Onboarding__wrapper">
            <div className="Onboarding__banner">
              <img src={OnboardImage} alt="OnboardImage" />
            </div>
            <div className="Onboarding__content">
              <div className="Onboarding__content_headline">
                <div className="Onboarding__flex">
                  <Link to={"/"}>
                    <div className="Auth_Back_mobile_btn">
                      <i className="fa-solid fa-arrow-left"></i>
                      <h1 className="auth_back_fs">{t("back")}</h1>
                    </div>
                  </Link>
                  <h1 className="auth_heading_fs">{t("onboard_heading")}</h1>
                  <img src={Celebration} alt="Celebration" />
                </div>

                <p className="auth_subheading_fs">
                  {t("onboard_subheading")}                  
                </p>
              </div>
              <div className="Onboarding__content_actions">
                <button
                  className="mi_btn mi_btn_medium mi_btn_primary"
                  onClick={() => Navigate("/")}
                >
                  <span> {t("onboard_button")} </span>
                  <i className="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
