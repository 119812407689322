import axios from 'axios';
import { clearAuthentication } from '../store/slices/authSlice';
import { store } from '../store/store';

const axiosInterceptors = () => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch(clearAuthentication());

        // Redirect to login page
        // window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInterceptors;
