import { createBrowserRouter } from 'react-router-dom';
import PageLayout from '../layouts/PageLayout/Pagelayout';
import Home from '../../shared/home/Home';
import AuthLayout from '../layouts/AuthLayout/AuthLayout';
import Login from '../../shared/connection/login/Login';
import Signup from '../../shared/connection/signup/Signup';
import SignupVerify from '../../shared/connection/signupVerify/SignupVerify';
import CreatePassword from '../../shared/connection/createPassword/CreatePassword';
import Forgot from '../../shared/connection/forgot/Forgot';
import ForgotVerify from '../../shared/connection/forgotVerify/ForgotVerify';
import ResetPassword from '../../shared/connection/resetPassword/ResetPassword';
import AdminDashboard from '../../admin/pages/adminDashboard/AdminDashboard';
import ManageUsers from '../../admin/pages/manageUsers/ManageUsers';
import ManageOrganization from '../../admin/pages/manageOrganization/ManageOrganization';
import ProductList from '../../admin/pages/productList/ProductList';
import Configuration from '../../admin/pages/configuration/Configuration';
import Threshold from '../../admin/pages/threshold/Threshold';
import Inventory from '../../users/pages/inventory/Inventory';
import Orders from '../../users/pages/orders/Orders';
import ViewOrders from '../../users/pages/viewOrders/ViewOrders';
import AddInventory from '../../users/pages/addInventory/AddInventory';
import ReviewInventory from '../../users/pages/reviewInvOrders/ReviewInventory';
import AddOrders from '../../users/pages/addOrders/AddOrders';
import ReviewOrders from '../../users/pages/reviewInvOrders/ReviewOrders';
import Onboard from '../../shared/connection/onboard/Onboard';
import Shipments from '../../users/pages/shipments/Shipments';
import ViewShipments from '../../users/pages/viewShipments/ViewShipments';
import VaccineArrivalReport from '../../users/pages/vaccineReport/VaccineArrivalReport';
import ReviewVAR from '../../users/pages/reviewVAR/ReviewVAR';
import AcceptShipment from '../../users/pages/acceptShipment/AcceptShipment';
import ServicePage from '../../users/pages/servicePage/ServicePage';
import CreateVAR from '../../users/pages/vaccineReport/CreateVAR';
import UserDashboard from '../../users/pages/userDashboard/UserDashboard';
import ViewVAR from '../../users/pages/viewVAR/ViewVAR';
import ErrorPage from '../../shared/errorPage/ErrorPage';
import ProtectedRoute from './routeLayers/ProtectedRoute';
import AuthRoute from './routeLayers/AuthRoute';
import ColdChain from '../../users/pages/coldChain/ColdChain';
import Maintenance from '../../users/pages/maintenance/Maintenance';
import FunctionalData from '../../users/pages/coldChain/functionalData/FunctionalData';
import HistoricalData from '../../users/pages/coldChain/historicalData/HistoricalData';
import AddJobCard from '../../users/pages/maintenance/jobCard/AddJobCard';
import Beneficiaries from '../../users/pages/beneficiary/Beneficiary';
import NewShipment from '../../users/pages/newShipment/NewShipment';
import AddBeneficiary from '../../users/pages/addBeneficiary/AddBeneficiary';
import CreatePAR from '../../users/pages/productReport/CreatePAR';
import ReviewPAR from '../../users/pages/reviewPAR/ReviewPAR';
import ViewPAR from '../../users/pages/viewPAR/ViewPAR';
import PermissionRoute from './routeLayers/PermissionRoute';
import UnAuthorized from '../../shared/errorPage/UnAuthorized';
import Campaign from '../../users/pages/campaign/Campaign';
import AddCampaign from '../../users/pages/campaign/AddCampaign';
import LoginHRIS from '../../shared/connection/loginHRIS/LoginHRIS';
import GeoTracking from '../../users/pages/geoTracking/GeoTracking';
import ViewProduct from '../../users/pages/viewProduct/ViewProduct';
import ReviewShipments from '../../users/pages/reviewShipment/ReviewShipments';
import InventoryRecall from '../../users/pages/inventoryRecall/InventoryRecall';
import CreateRecall from '../../users/pages/inventoryRecall/createRecall/CreateRecall';
import InventoryAdjustment from '../../users/pages/inventoryAdjustment/InventoryAdjustment';
import CreateAdjustment from '../../users/pages/createAdjustment/CreateAdjustment';
import DataVisualization from '../../users/pages/dataVisualization/DataVisualization';
import ReverseShipment from '../../users/pages/reverseShipment/ReverseShipment';
import TransportRoute from '../../users/pages/transportRoute/TransportRoute';
import UpdateVVM from '../../users/pages/updateVVM/UpdateVVM';
import AutoIndent from '../../users/pages/autoIndent/AutoIndent';
import AutoIndentForm from '../../users/pages/autoIndent/AutoForm/AutoIndentForm';
import MinMaxIndentForm from '../../users/pages/autoIndent/minMaxForm/MinMaxForm';
import ConsumptionIndentForm from '../../users/pages/autoIndent/consumptionForm/ConsumptionForm';
import AssetTransfer from '../../users/pages/assetTransfer/AssetTransfer';
import CreateAssetTransfer from '../../users/pages/createAssetTransfer/CreateAssetTransfer';
import AddAssets from '../../users/pages/addAssets/AddAssets';
import ViewTrip from '../../users/pages/viewTrips/ViewTrip';
import DraftIndents from '../../users/pages/draftIndents/DraftIndents';

const PageRoutes = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthRoute>
        <Home />
      </AuthRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/',
    element: (
      <AuthRoute>
        <AuthLayout />
      </AuthRoute>
    ),
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'login/hris',
        element: <LoginHRIS />,
      },
      {
        path: 'signup',
        element: <Signup />,
      },
      {
        path: 'signup/verify',
        element: <SignupVerify />,
      },
      {
        path: 'signup/create-password',
        element: <CreatePassword />,
      },
      {
        path: 'signup/onboarding',
        element: <Onboard />,
      },
      {
        path: 'forgot',
        element: <Forgot />,
      },
      {
        path: 'forgot/verify',
        element: <ForgotVerify />,
      },
      {
        path: 'forgot/reset-password',
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <PageLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'admin/dashboard',
        element: (
          <PermissionRoute allowed={'FULL_ADMIN'}>
            <AdminDashboard />
          </PermissionRoute>
        ),
      },
      {
        path: 'admin/manage-users',

        element: (
          <PermissionRoute allowed={'FULL_ADMIN'}>
            <ManageUsers />
          </PermissionRoute>
        ),
      },
      {
        path: 'admin/manage-organization',

        element: (
          <PermissionRoute allowed={'FULL_ADMIN'}>
            <ManageOrganization />
          </PermissionRoute>
        ),
      },
      {
        path: 'admin/product-list',

        element: (
          <PermissionRoute allowed={'FULL_ADMIN'}>
            <ProductList />
          </PermissionRoute>
        ),
      },
      {
        path: 'admin/configuration',

        element: (
          <PermissionRoute allowed={'FULL_ADMIN'}>
            <Configuration />
          </PermissionRoute>
        ),
      },
      {
        path: 'admin/threshold',

        element: (
          <PermissionRoute allowed={'FULL_ADMIN'}>
            <Threshold />
          </PermissionRoute>
        ),
      },
    ],
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <PageLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'user/dashboard',
        element: (
          <PermissionRoute allowed={'OVERVIEW'}>
            <UserDashboard />
          </PermissionRoute>
        ),
      },

      {
        path: 'user/orders',

        element: <Orders />,
      },
      {
        path: 'user/orders/add-orders',

        element: (
          <PermissionRoute allowed={'CREATE_ORDER'}>
            <AddOrders />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/orders/review-orders',

        element: (
          <PermissionRoute allowed={'CREATE_ORDER'}>
            <ReviewOrders />
          </PermissionRoute>
        ),
      },

      {
        path: 'user/orders/view-order/:id',

        element: (
          <PermissionRoute allowed={'VIEW_ORDER'}>
            <ViewOrders />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/last-mile',

        element: (
          <PermissionRoute allowed={'LAST_MILE'}>
            <Beneficiaries />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/beneficiary/add-beneficiary',

        element: (
          <PermissionRoute allowed={'LAST_MILE'}>
            <AddBeneficiary />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/inventory',

        element: <Inventory />,
      },
      {
        path: 'user/inventory/add-inventory',

        element: (
          <PermissionRoute allowed={'CREATE_INVENTORY'}>
            <AddInventory />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/inventory/review-inventory',

        element: (
          <PermissionRoute allowed={'CREATE_INVENTORY'}>
            <ReviewInventory />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/inventory/recall',

        element: (
          <PermissionRoute allowed={'CREATE_INVENTORY'}>
            <InventoryRecall />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/inventory/recall/new',

        element: (
          <PermissionRoute allowed={'CREATE_INVENTORY'}>
            <CreateRecall />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/inventory/adjustment/:tab',

        element: (
          <PermissionRoute allowed={'CREATE_INVENTORY'}>
            <InventoryAdjustment />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/inventory/adjustment/new/:url',
        element: (
          <PermissionRoute allowed={'CREATE_INVENTORY'}>
            <CreateAdjustment />
          </PermissionRoute>
        ),
      },

      {
        path: 'user/inventory/adjustment/vvm-status',

        element: (
          <PermissionRoute allowed={'CREATE_INVENTORY'}>
            <UpdateVVM />
          </PermissionRoute>
        ),
      },

      {
        path: 'user/shipments',
        element: <Shipments currentShipment='outbound' />,
      },
      {
        path: 'user/shipments/inbound',
        element: <Shipments currentShipment='inbound' />,
      },

      {
        path: 'user/shipments/new-shipments',

        element: (
          <PermissionRoute allowed={'CREATE_SHIPMENT'}>
            <NewShipment />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/shipments/new-shipments/:id',

        element: (
          <PermissionRoute allowed={'CREATE_SHIPMENT'}>
            <NewShipment />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/shipments/review-shipments',

        element: (
          <PermissionRoute allowed={'CREATE_SHIPMENT'}>
            <ReviewShipments />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/shipments/view-shipment/:id',

        element: (
          <PermissionRoute allowed={'VIEW_SHIPMENT'}>
            <ViewShipments />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/shipments/reverse-shipments',

        element: (
          <PermissionRoute allowed={'CREATE_SHIPMENT'}>
            <ReverseShipment />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/shipments/accept-shipments/:id',

        element: (
          <PermissionRoute allowed={'RECEIVE_SHIPMENT'}>
            <AcceptShipment />
          </PermissionRoute>
        ),
      },

      {
        path: 'user/arrival-report/vaccine',

        element: (
          <PermissionRoute allowed={'VIEW_VAR'}>
            <VaccineArrivalReport currentTab='VAR' />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/arrival-report/product',

        element: (
          <PermissionRoute allowed={'VIEW_PAR'}>
            <VaccineArrivalReport currentTab='PAR' />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/arrival-report/vaccine/new',

        element: (
          <PermissionRoute allowed={'ADD_VAR'}>
            <CreateVAR />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/arrival-report/vaccine/review',

        element: (
          <PermissionRoute allowed={'ADD_VAR'}>
            <ReviewVAR />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/vaccine-arrival-report/view-var/:id',

        element: (
          <PermissionRoute allowed={'VIEW_VAR'}>
            <ViewVAR />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/arrival-report/product/new',

        element: (
          <PermissionRoute allowed={'ADD_PAR'}>
            <CreatePAR />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/arrival-report/product/review',

        element: (
          <PermissionRoute allowed={'ADD_PAR'}>
            <ReviewPAR />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/arrival-report/product/view/:id',

        element: (
          <PermissionRoute allowed={'VIEW_PAR'}>
            <ViewPAR />
          </PermissionRoute>
        ),
      },

      {
        path: 'user/cold-chain',

        element: (
          <PermissionRoute allowed={'VIEW_COLDCHAIN'}>
            <ColdChain />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/cold-chain/add/assets',

        element: (
          <PermissionRoute allowed={'VIEW_COLDCHAIN'}>
            <AddAssets />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/assets-transfer',

        element: (
          <PermissionRoute allowed={'VIEW_COLDCHAIN'}>
            <AssetTransfer />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/assets-transfer/new',

        element: (
          <PermissionRoute allowed={'VIEW_COLDCHAIN'}>
            <CreateAssetTransfer />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/view-functional-status',

        element: (
          <PermissionRoute allowed={'VIEW_COLDCHAIN'}>
            <FunctionalData />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/view-historical-data/:id',

        element: (
          <PermissionRoute allowed={'VIEW_COLDCHAIN'}>
            <HistoricalData />
          </PermissionRoute>
        ),
      },

      {
        path: 'user/maintenance',

        element: (
          <PermissionRoute allowed={'VIEW_MAINTENANCE'}>
            <Maintenance />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/maintenance/job',

        element: (
          <PermissionRoute allowed={'ADD_MAINTENANCE'}>
            <AddJobCard />
          </PermissionRoute>
        ),
      },
      {
        path: 'user/data-visualization/info',
        element: <DataVisualization />,
      },
      {
        path: 'user/campaign/manage',
        element: <Campaign />,
      },
      {
        path: 'user/campaign/:url',
        element: <AddCampaign />,
      },
      {
        path: 'user/data-visualization',
        element: <GeoTracking />,
      },
      {
        path: 'user/geo-tracking/:url',
        element: <TransportRoute />,
      },
      {
        path: 'user/geo-tracking/history/:id',
        element: <ViewTrip />,
      },
      {
        path: 'user/services/:url',
        element: <ServicePage />,
      },

      {
        path: 'user/view-product',
        element: <ViewProduct />,
      },

      {
        path: 'user/need-forecasting/:tab',
        element: <AutoIndent />,
      },
      {
        path: 'user/need-forecasting/draft/:tab',
        element: <DraftIndents />,
      },
      {
        path: 'user/auto-indent/auto',
        element: <AutoIndentForm />,
      },
      {
        path: 'user/auto-indent/consumption',
        element: <ConsumptionIndentForm />,
      },
      {
        path: 'user/auto-indent/min-max',
        element: <MinMaxIndentForm />,
      },
    ],
  },

  {
    path: '/unauthorized',
    element: <UnAuthorized />,
  },
]);

export default PageRoutes;

