import { configureStore } from "@reduxjs/toolkit";
import popupSlice from "./slices/popupSlice";
import authSlice from "./slices/authSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    popup: popupSlice,
  },
});
