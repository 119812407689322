import { createSlice } from "@reduxjs/toolkit";

let userInfoString = localStorage.getItem("userInfo");

const initialState = {
  theToken: localStorage.getItem("theToken")
    ? localStorage.getItem("theToken")
    : null,
  userInfo: userInfoString ? JSON.parse(userInfoString) : null,
  roleInfo: {},
  permissions: localStorage.getItem("permissions")
    ? localStorage.getItem("permissions")
    : [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userAuthentication: (state, action) => {
      state.theToken = action.payload.theToken;
      state.userInfo = action.payload.userInfo;
      state.roleInfo = action.payload.roleInfo;
      state.permissions = action.payload.permissions;
      localStorage.setItem("theToken", state.theToken);
      localStorage.setItem("permissions", state.permissions);
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },

    clearAuthentication: (state) => {
      state.theToken = null;
      state.userInfo = {};
      state.roleInfo = {};
      state.permissions = {};
      localStorage.removeItem("theToken");
      localStorage.removeItem("permissions");
      localStorage.removeItem("userInfo");
    },
  },
});

export const { userAuthentication, clearAuthentication } = authSlice.actions;
export default authSlice.reducer;
