
import axios from "axios";

const setAuthToken = () => {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    //   if (token) {
    //     // Apply to every request
    //     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    //   } else {
    //     // Delete auth header
    //     delete axios.defaults.headers.common["Authorization"];
    //   }
};

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('theToken') ? localStorage.getItem('theToken') : localStorage.getItem('theTokenTemp');
    config.headers.Authorization = token;

    return config;
});

export default setAuthToken;
