import './Header.css';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Image Imports
import Search from '/icons/all/Search.svg';
import Settings from '/icons/all/Settings.svg';
import UserProfile from '/icons/all/User Profile.svg';
import Notifications from '/icons/all/Notifications.svg';
import switchAdmin from '/icons/all/switchAdmin.svg';
import LanguageSwitch from '/icons/all/languagechange.svg';
import SignOut from '/icons/all/signout.svg';

import MOH from '/branding/MOH logo.png';
import DGHS from '/branding/DGHS logo.png';
import EPI from '/branding/EPI logo.png';
import MIS from '/branding/MIS logo.png';
import EnglishFlag from '/icons/flags/English.webp';
import BanglaFlag from '/icons/flags/Bangla.png';
import {
  changeUserLocations,
  getUserInfo,
  getUserRole,
  logout,
} from '../../redux/userActions';
import { getUserLocations } from '../../redux/inventoryActions';
import {
  clearAuthentication,
  userAuthentication,
} from '../../store/slices/authSlice';
import { getUserLocationInfo } from '../../redux/orderActions';
import PermissionRoute from '../../web/routes/routeLayers/PermissionRoute';
import { jwtDecode } from 'jwt-decode';
import { BRAND_LOGO } from '../../constant';
import NotificationDetails from '../../users/components/notificationDetails/NotificationDetails';
import EmptyTable from '../utils/emptyTable/EmptyTable';
import { useGetUserInfo } from '../../services/user-services/queries';
import { API_URL } from '../../config';

let useClickOutside = (handler) => {
  let ProfileNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!ProfileNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener('mousedown', maybeHandler);

    return () => {
      document.removeEventListener('mousedown', maybeHandler);
    };
  });

  return ProfileNode;
};
export default function Header({ setSidebarOpen }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [unReadNotification, setUnReadNotification] = useState(true);
  const [userLocations, setUserLocations] = useState();
  const [userLocationInfo, setUserLocationInfo] = useState();
  const [searchCapture, setSearchCapture] = useState();
  const [profileClickBtn, setProfileClickBtn] = useState(false);
  const [notificationBtn, setNotificationBtn] = useState(false);
  const [locationBtn, setLocationBtn] = useState(false);

  const [locationUpdated, setLocationUpdated] = useState(false);

  const { data: userInfo } = useGetUserInfo();

  const ProfileNode = useClickOutside(() => {
    setProfileClickBtn(false);
  });

  const NotificationNode = useClickOutside(() => {
    setNotificationBtn(false);
  });

  const LocationNode = useClickOutside(() => {
    setLocationBtn(false);
  });

  useEffect(() => {
    const fetchData = async () => {
      const results = await getUserLocations();
      setUserLocations(results?.data?.locations);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getUserLocationInfo();
      setUserLocationInfo(result.data);
    };
    fetchData();
  }, [locationUpdated]);

  const handleSignOut = async () => {
    await logout();
    localStorage.removeItem('theToken');
    localStorage.removeItem('user');
    localStorage.removeItem('theTokenTemp');
    localStorage.removeItem('OrdersTab');
    dispatch(clearAuthentication());
    navigate('/');
  };

  const changeLanguage = (lng) => {
    setProfileClickBtn(false);
    toast.success(
      lng === 'en'
        ? 'Language has been changed to English'
        : 'ভাষা পরিবর্তন করে বাংলা করা হয়েছে'
    );
    i18n.changeLanguage(lng);
  };

  const handleLocationChange = async (e, id) => {
    e.preventDefault();
    const res = await changeUserLocations({ locationId: id });

    setLocationBtn(false);
    if (res.success) {
      const userDetails = jwtDecode(res.data.token);

      const userPermissions = await getUserRole(userDetails?.roleId);

      dispatch(
        userAuthentication({
          theToken: res.data.token,
          userInfo: userDetails,
          roleInfo: userPermissions?.data?._doc,
          permissions: userPermissions?.data?.activePermissions,
        })
      );
      setLocationUpdated((prev) => !prev);
      toast.success('Current Location Changed');
      window.location.reload();
    } else {
      setApiErrors(res.data.message);
    }
  };

  const ImageWithFallback = ({ src, fallbackSrc, alt }) => {
    return (
      <img
        src={src}
        alt={alt}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = fallbackSrc;
        }}
      />
    );
  };
  const imageUrl = userInfo?.photoId
    ? `${API_URL.imagesUrl}/${userInfo?.photoId}`
    : UserProfile;

  return (
    <header className='Header__main_container'>
      <nav className='Header__navbar_wrapper'>
        <div
          className='mobile_menu_icon'
          onClick={() => setSidebarOpen((prev) => !prev)}
        >
          <i className='fa-solid fa-bars'></i>
        </div>
        <Link
          to={
            location.pathname.includes('/admin')
              ? '/admin/dashboard'
              : '/user/dashboard'
          }
        >
          <figure className='Header__brand_logo'>
            <img src={BRAND_LOGO} alt='website logo' />
            <div className='Header__brand_support'>
              <img src={MOH} alt='website logo' />
              {/* <img src={DGHS} alt="website logo" />
              <img src={EPI} alt="website logo" />
              <img src={MIS} alt="website logo" /> */}
            </div>
          </figure>
        </Link>

        <ul className='Header__menu_list'>
          <PermissionRoute allowed={'ALL_SEARCH'} type='actions'>
            {' '}
            <li className='Header_search_btn'>
              <article className='header_search'>
                <img src={Search} className='Search__icon' />
                <input
                  type='text'
                  placeholder={t('search')}
                  className='search'
                  onInput={(e) => {
                    setSearchCapture(e.target.value);
                  }}
                />
              </article>
            </li>
          </PermissionRoute>

          <li className='Header__menu_item' ref={LocationNode}>
            <div className='user__location'>
              <div
                className='Header__location_wrapper'
                onClick={() => setLocationBtn(!locationBtn)}
              >
                <i className='fa-solid fa-location-dot'></i>
                <div
                  className='Input__column Location_dropdown'
                  style={{ marginRight: '0.3rem' }}
                >
                  <div
                    className='loc_text'
                    style={{ paddingBlock: '0.65rem', whiteSpace: 'nowrap' }}
                  >
                    {' '}
                    {userLocationInfo?.name?.length > 20
                      ? userLocationInfo?.name.substring(0, 20) + '... '
                      : userLocationInfo?.name}
                  </div>
                </div>
                <i className='fa-solid fa-caret-down'></i>
              </div>
              <div
                className={`user__location_dropdown ${locationBtn && 'active'}`}
              >
                {userLocations?.map((level, index) => (
                  <div
                    className='user__location_dropdown_card'
                    key={index}
                    onClick={(e) => handleLocationChange(e, level?._id)}
                  >
                    <i className='fa-solid fa-location-dot'></i>
                    <p className='page__note_fs'>{level?.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </li>

          <li className='Header__menu_item' ref={NotificationNode}>
            <div className='Header__notification_wrapper'>
              <div
                className='Header_notifcation_icon'
                onClick={() => setNotificationBtn(!notificationBtn)}
              >
                <img
                  src={Notifications}
                  alt=''
                  style={{ height: '22px' }}
                  className='white_icon'
                />
                <span
                  className={`notifcation_active_dot ${
                    unReadNotification && 'active'
                  }`}
                ></span>
              </div>
              <div
                className={`Header__notification_dropdown ${
                  notificationBtn && 'active'
                }`}
              >
                <div className='UserDashboard__inv_summary'>
                  <div>
                    <div className='UserDashboard__summary_card_header'>
                      <h1 className='page__body_heading_fs mi_white'>
                        {t('news_notifications')}
                      </h1>
                    </div>
                    <div className='Notification__body_scroll'>
                      <NotificationDetails notificationBtn={notificationBtn} />
                      {/* <EmptyTable message='No notifications available' /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className='Header__menu_item' ref={ProfileNode}>
            <div className='Header__profile_wrapper'>
              <div
                className='Header__profile_space'
                onClick={() => setProfileClickBtn(!profileClickBtn)}
              >
                <div className='Header__profile_picture'>
                  <ImageWithFallback
                    src={imageUrl}
                    fallbackSrc={UserProfile}
                    alt='Profile'
                  />
                </div>
              </div>

              <div
                className={`Header__profile_dropdown ${
                  profileClickBtn && 'active'
                }`}
              >
                <div className='Profile_dropdown_list'>
                  <div className='Profile_dropdown_header'>
                    <div className='dropdown_header_user'>
                      <div className='Header__profile_space_inner'>
                        <div className='Header__profile_picture_inner'>
                          <ImageWithFallback
                            src={imageUrl}
                            fallbackSrc={UserProfile}
                            alt='Profile'
                          />
                        </div>
                      </div>
                      <div className='dropdown__header_info'>
                        <div className='user_name_and_role'>
                          <h1 className='User_name_fs no_space_wrap'>
                            {userInfo?.firstName + ' ' + userInfo?.lastName}
                          </h1>
                          <div className='Role_ico'>
                            {location.pathname.includes('/admin') ? (
                              <span>{t('admin')}</span>
                            ) : (
                              <span>{t('user')}</span>
                            )}
                          </div>
                        </div>
                        <p className='org_name_fs'>{userInfo?.roleId?.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className='Profile_dropdown_body'>
                    <PermissionRoute allowed={'FULL_ADMIN'} type='actions'>
                      {location.pathname.includes('/admin') ? (
                        <div
                          onClick={() => {
                            navigate('/user/dashboard');
                            setProfileClickBtn(false);
                          }}
                          className='dropdown__menu'
                        >
                          <img
                            src={switchAdmin}
                            alt=''
                            className='Search__icon'
                          />
                          <p className='dropdown__menu_link'>
                            {t('switch_to')} {'  '} {t('user')}
                          </p>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            navigate('/admin/dashboard');
                            setProfileClickBtn(false);
                          }}
                          className='dropdown__menu'
                        >
                          <img
                            src={switchAdmin}
                            alt=''
                            className='Search__icon'
                          />
                          <p className='dropdown__menu_link'>
                            {t('switch_to')}
                            {'  '}
                            {t('admin')}
                          </p>
                        </div>
                      )}
                    </PermissionRoute>

                    <div
                      onClick={() => {
                        navigate('/user/services/profile');
                        setProfileClickBtn(false);
                      }}
                      className='dropdown__menu'
                    >
                      <img src={UserProfile} alt='' className='Search__icon' />
                      <p className='dropdown__menu_link'>{t('profile')}</p>
                    </div>
                    <div
                      onClick={() => {
                        navigate('/user/services/settings');
                        setProfileClickBtn(false);
                      }}
                      className='dropdown__menu'
                    >
                      <img src={Settings} alt='' className='Search__icon' />
                      <p className='dropdown__menu_link'>{t('settings')}</p>
                    </div>

                    <div
                      onClick={() => {
                        if (i18n.language === 'en') {
                          changeLanguage('bn');
                        } else {
                          changeLanguage('en');
                        }
                      }}
                      className='dropdown__menu language__menu'
                    >
                      <div className='lang__info'>
                        <img
                          src={LanguageSwitch}
                          alt=''
                          className='Search__icon'
                        />
                        <p className='dropdown__menu_link'>
                          {t('change_language')}
                        </p>
                      </div>

                      {i18n.language === 'en' ? (
                        <div className='flag-circle'>
                          <img
                            src={BanglaFlag}
                            alt='flags'
                            className='flag-circle-image'
                          />
                        </div>
                      ) : (
                        <div className='flag-circle'>
                          <img
                            src={EnglishFlag}
                            alt='flags'
                            className='flag-circle-image'
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='Profile_dropdown_footer'>
                    <div className='dropdown__menu' onClick={handleSignOut}>
                      <img src={SignOut} alt='' className='Search__icon' />
                      <p className='dropdown__menu_link'>{t('sign_out')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>

      {profileClickBtn && (
        <div
          className={`Background__overlay ${profileClickBtn && 'active'}`}
        ></div>
      )}
      {notificationBtn && (
        <div
          className={`Background__overlay ${notificationBtn && 'active'}`}
        ></div>
      )}
    </header>
  );
}
