import React from "react";
import { useTranslation } from "react-i18next";

export default function AssignRole(props) {
  const { customError } = props;
  const {t} = useTranslation()

  return (
    <React.Fragment>
        {customError?.length > 1 && (  
            <div className="ModalPopup__section"> 
               <div className='ModalPopup__form_section'>              
                <div className='Input__table'>
                <div className='Input__row '>
                 {/* <h1 className="Password__heading">Password must meet the following requirements:</h1> */}
                  <div className='Input__column'>
                    <div className={customError.includes("At least one lowercase letter") != true 
                        ? 'Item_green' : 'Item_flex'}>                            
                      { customError.includes("At least one lowercase letter") != true 
                        ?  <i className='fa-solid fa-check fa-sm'></i>  :  <i className='fa-solid fa-xmark fa-sm'></i> }
                       <p className='Password__text_fs'>{t("lowercase_letter")}</p>     
                    </div>
                    <div className={customError.includes("At least one uppercase letter") != true 
                       ? 'Item_green' : 'Item_flex'}>                           
                      { customError.includes("At least one uppercase letter") != true 
                       ?  <i className='fa-solid fa-check fa-sm'></i>  :  <i className='fa-solid fa-xmark fa-sm'></i> }
                       <p className='Password__text_fs'>{t("uppercase_letter")}</p>    
                    </div>
                    <div className={customError.includes("At least one number") != true 
                      ? 'Item_green' : 'Item_flex'}>                            
                      { customError.includes("At least one number") != true 
                      ?  <i className='fa-solid fa-check fa-sm'></i>  :  <i className='fa-solid fa-xmark fa-sm'></i> }
                       <p className='Password__text_fs'>{t("one_number")}</p>    
                    </div>
                    <div className={customError.includes("At least one special character") != true 
                      ? 'Item_green' : 'Item_flex'}>                          
                      { customError.includes("At least one special character") != true 
                      ?  <i className='fa-solid fa-check fa-sm'></i>  :  <i className='fa-solid fa-xmark fa-sm'></i> }
                        <p className='Password__text_fs'>{t("special_character")} </p>     
                    </div> 
                    <div className={customError.includes("Be at least 6 characters long") != true 
                     ? 'Item_green' : 'Item_flex'}>                          
                      { customError.includes("Be at least 6 characters long") != true 
                      ?  <i className='fa-solid fa-check fa-sm'></i>  :  <i className='fa-solid fa-xmark fa-sm'></i> }
                        <p className='Password__text_fs'>{t("six_character_long")} </p>     
                    </div>                    
                  </div>
                </div>
                </div>
              </div>
            </div>
            )}
    </React.Fragment>
  );
}
