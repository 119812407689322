import React from "react";
import "../createPassword/CreatePassword.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PasswordPattern from "../createPassword/PasswordPattern";

// Image Import
import LockIcon from "/icons/lock-grey.svg";

// Mui Imports
import { updatePwd } from "../../../redux/userActions";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  const Navigate = useNavigate();
  const params = useLocation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [customError, setCustomError] = React.useState("");
  const { t } = useTranslation();
  React.useEffect(() => {
    if (params.state?.values == undefined) Navigate("/");
  }, [Navigate, params]);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is Required")
      .matches(/^\S*$/, "Invalid Password. Password must not contain spaces.")
      .matches(/^(?=.*[a-z])/, { message: "At least one lowercase letter" })
      .matches(/^(?=.*[A-Z])/, { message: "At least one uppercase letter" })
      .matches(/^(?=.*\d)/, { message: "At least one number" })
      .matches(/^(?=.*[@$!%*?&])/, {
        message: "At least one special character",
      })
      .min(6, "Be at least 6 characters long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{6,}/,
        "Password must be at least 6 characters long and contain at least lowercase letter, one uppercase letter, one number, and one special character. (@$!%*?&)",
      ),
    confirmPassword: Yup.string()
      .required(t("confirm_password_error"))
      .oneOf([Yup.ref("password"), null], t("passwords_match_error")),
  });

  const validate = async (values) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });
      setCustomError("");
    } catch (error) {
      const firstValidationError = error.errors;
      setCustomError(firstValidationError);
    }
  };

  return (
    <div className='Authform__container'>
      <Link to={"/"}>
        <div className='Authform__back_button'>
          <i className='fa-solid fa-arrow-left'></i>
          <h1 className='auth_back_fs'>{t("back")}</h1>
        </div>
      </Link>
      <div className='Authform__wrapper single_column Parent__error_popup'>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          validate={validate}
          onSubmit={async (values) => {
            const res = await updatePwd(values);
            if (res.success === true) {
              Navigate("/login", { state: { value: params.user } });
              toast.success("Password Reset, Try login");
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <form className='Authform__form_group' onSubmit={handleSubmit}>
              <div className='Authform__headline_space'>
                <Link to={"/"}>
                  <div className='Auth_Back_mobile_btn'>
                    <i className='fa-solid fa-arrow-left'></i>
                    <h1 className='auth_back_fs'>{t("back")}</h1>
                  </div>
                </Link>
                <h1 className='auth_heading_fs'>{t("reset_pwd_title")}</h1>
                <p className='auth_subheading_fs'>{t("create_pwd_subtitle")}</p>
              </div>
              <div className='Authform__form_body'>
                <div className='Input__table'>
                  <div className='Input__row single_column'>
                    <div className='Input__column'>
                      <p className='Input__label_fs'>
                        {t("password")}
                        <b>*</b>
                      </p>
                      <div className='Input__password_field'>
                        <Field
                          type={showPassword ? "text" : "password"}
                          className='mi_custom_textfield'
                          id='password'
                          name='password'
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />

                        <div
                          className='hide_show_icon'
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <i className='fa-regular fa-eye-slash'></i>
                          ) : (
                            <i className='fa-regular fa-eye'></i>
                          )}
                        </div>
                        <div className='Item_green Tick__right_icon'>
                          {customError?.length <= 1 && values.password && (
                            <i className='fa-solid fa-check fa-sm'></i>
                          )}
                        </div>
                      </div>
                      {/* <ErrorMessage  className="Input__error_fs" name="password" component="div" /> */}
                      {customError.includes("Password is Required") && (
                        <span className='Input__error_fs'>
                          {t("password_error")}
                        </span>
                      )}
                      {customError.includes(
                        "Password must be at least 6 characters long and contain at least lowercase letter, one uppercase letter, one number, and one special character. (@$!%*?&)",
                      ) && (
                        <span className='Input__error_fs Error__mobile_validation'>
                          {" "}
                          {t("strong_password_msg")}
                          (@$!%*?&){" "}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className='Input__row single_column'>
                    <div className='Input__column'>
                      <p className='Input__label_fs'>
                        {t("confirm_password")}
                        <b>*</b>
                      </p>
                      <div className='Input__password_field'>
                        <Field
                          type={showPassword1 ? "text" : "password"}
                          className='mi_custom_textfield'
                          id='confirmPassword'
                          name='confirmPassword'
                        />

                        <div
                          className='hide_show_icon'
                          onClick={() => setShowPassword1(!showPassword1)}
                        >
                          {showPassword1 ? (
                            <i className='fa-regular fa-eye-slash'></i>
                          ) : (
                            <i className='fa-regular fa-eye'></i>
                          )}
                        </div>
                      </div>
                      <ErrorMessage
                        className='Input__error_fs'
                        name='confirmPassword'
                        component='div'
                      />
                    </div>
                  </div>
                  <div className='Input__row single_column'>
                    <div className='Authform__secure_button'>
                      <img src={LockIcon} alt='LockIcon' />
                      <p className='auth__secure_fs'>{t("security_msg")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='Authform__form_actions'>
                <div className='Authform__submit_button'>
                  <button
                    type='submit'
                    className='mi_btn mi_btn_medium mi_btn_primary'
                  >
                    <span>{t("reset_pwd_button")}</span>
                    <i className='fa-solid fa-arrow-right'></i>
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <PasswordPattern customError={customError} />
      </div>
    </div>
  );
}
