import { RouterProvider } from "react-router-dom";
import PageRoutes from "./routes/Pageroutes";
import { Toaster } from "react-hot-toast";

export default function App() {
  return (
    <>
      <RouterProvider router={PageRoutes} />
      <Toaster
        position='top-right'
        reverseOrder={false}
        toastOptions={{
          success: {
            duration: 750,
          },
          error: {
            duration: 2000,
          },
        }}
      />
    </>
  );
}
