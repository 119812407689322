import React from 'react';
import HomeHeader from './HomeHeader/HomeHeader';
import HomeShowcase from './HomeShowcase/HomeShowcase';
import Footer from '../footer/Footer';

export default function Home() {
  return (
    <React.Fragment>
      <HomeHeader />
      <HomeShowcase />
      <Footer />
    </React.Fragment>
  );
}
